import React, { useState, useEffect } from "react";
import marked from "marked";
import { getBiog } from "../contentful";
import PageTitle from "./PageTitle/PageTitle";

const Biog = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getBiog().then((data) => setContent(data[0].fields));
  }, []);
  return (
    <>
      <PageTitle title='Oren Marshall' />

      <div className='page-content biog-page'>
        {content.image && (
          <div className='biog-page__image'>
            <img src={content.image.fields.file.url} />
          </div>
        )}
        {content.text && (
          <section
            className='biog-page__text'
            dangerouslySetInnerHTML={{ __html: marked(content.text) }}
          />
        )}
      </div>
    </>
  );
};

export default Biog;
